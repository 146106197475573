import React from 'react';
import { Layout } from '../../components/Layout';
import { StatusPlanCorporate } from '../../components/StatusPlanCorporate';

const statusProcessing = {
  titleStatus: 'Pagamento em processamento',
  textStatus: 'Aguardando a confirmação do pagamento.',
  icon: 'SvgIconHourglass',
};
const statusContinue = {
  titleStatus: 'Seu plano vence em 5 dias',
  textStatus: 'Faça o pagamento para continuar anunciando.',
  textButton: 'Pagar',
  textLink: 'Alterar plano',
  icon: 'SvgIconWarning',
};

const SandBoxStatusPlanCorporate = () => (
  <Layout>
    <StatusPlanCorporate status={statusProcessing} isProcessing />
    <br />
    <StatusPlanCorporate status={statusContinue} />
  </Layout>
);

export default SandBoxStatusPlanCorporate;
