import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import { ActionsStatusPlan } from './components/ActionsStatusPlan';
import styles from './style.module.css';

interface StatusProps {
  titleStatus: string;
  textStatus: string;
  textButton?: string;
  textLink?: string;
  icon: string;
}

interface Props {
  status: StatusProps;
  isProcessing?: boolean;
}

export const StatusPlanCorporate = ({ status, isProcessing = false }: Props) => {
  const { titleStatus, textStatus, textButton, textLink, icon } = status;
  const paragraph = cx('f13 fw4 ma0 gray lh-copy', styles.paragraph);
  const container = cx(
    'flex flex-column flex-row-m flex-row-l justify-between-m justify-between-l bg-white ph12 pl16-ns pr12-ns pt12 pt16-m pt16-l pb16 pb12-ns br3 bw1 b--solid',
    styles.container,
  );
  const iconClass = cx('mr12', { 'purpley-pink': !isProcessing, abbey: isProcessing });

  return (
    <div className={container}>
      <div className="flex items-center w-100">
        <div className={iconClass}>
          <Icon name={icon} width={24} height={24} />
        </div>
        <div>
          <h4 className="f14 fw6 ma0 abbey lh-copy">{titleStatus}</h4>
          <p className={paragraph}>{textStatus}</p>
        </div>
      </div>
      {!isProcessing && <ActionsStatusPlan textButton={textButton} textLink={textLink} />}
    </div>
  );
};
