import React from 'react';
import { Button } from '../../Button';
import { Link } from '../../Link';
import styles from '../style.module.css';

interface Props {
  textButton?: string;
  textLink?: string;
}

export const ActionsStatusPlan = ({ textButton, textLink }: Props) => {
  const handlePayment = () => {
    // eslint-disable-next-line no-console
    console.log('pagamento efetuado');
  };
  const handleChangePlan = () => {
    // eslint-disable-next-line no-console
    console.log('plano alterado');
  };
  return (
    <div className="flex flex-column flex-row-reverse-ns items-center justify-start-ns mt12 mt0-m mt0-l w-100">
      <Button classButton={styles.btnBilling} onClick={handlePayment} size="medium">
        {textButton}
      </Button>
      <Link
        className="f14 ttu fw7 mt24 mt0-ns mr48-ns mr40-m mr40-l pointer"
        onClick={handleChangePlan}
      >
        {textLink}
      </Link>
    </div>
  );
};
